import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import { kebabCase } from 'lodash'
import PreviewCompatibleImage from './PreviewCompatibleImage'

class BlogRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div className="posts-container">
      {posts &&
        posts.map(({ node: post }) => (
        <div className="post">
          <article
            key={post.id}
          >
            <header>
              {post.frontmatter.authorimage ? (
                <div>
                  <div className="photo">
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: post.frontmatter.authorimage,
                        alt: `featured image thumbnail for post ${post.frontmatter.authorname}`,
                      }}
                    />
                  </div>
                  <span className="underline"></span>
                </div>
              ) : null}
              <p className="tags">
                <Link
                  to={`/category/${kebabCase(post.frontmatter.category)}/`}
                >
                  {post.frontmatter.category}
                </Link>
                <span className="separator"> / </span>
                <span>
                  {(post.frontmatter.date).split("/").join(".")}
                </span>
              </p>
            </header>
            <h3><Link to={post.fields.slug}>{post.frontmatter.title}</Link></h3>
            <div>
              {post.excerpt}
            </div>
            <Link className="read-more" to={post.fields.slug}>Read More</Link>
          </article>
        </div>
        ))}
      </div>
    )
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date
                authorbio
                authorname
                authorimage {
                  childImageSharp {
                    fluid(maxWidth: 85, quality: 85) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                  publicURL
                }
                category
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)
