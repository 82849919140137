import React from 'react'

import Layout from '../../components/layout'
import BlogRoll from '../../components/BlogRoll'

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <section className="blog-entries">
          <header className="header">
            <h2>Recent journal entries</h2>
            <p>Our blog will cover a wide range of topics such as the latest SEO or PPC updates and strategies, email marketing automation, web design trends, new web development technology and various other tips and tricks to help you broaden your knowledge in this ever-changing digital world.</p>
          </header>
          <BlogRoll />
        </section>
      </Layout>
    )
  }
}
